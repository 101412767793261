import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Images = () => (
  <Layout>
    <Seo title="Page two" />
    <h1>Images</h1>
  </Layout>
)

export default Images
